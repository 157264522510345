import { Button, Select, SelectItem, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { UsersGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { useAuthWhoamiGet } from "api/useAuthWhoamiGet";
import useUsersMutationQuery from "api/user/useUserMutation";
import { users } from "api/user/useUserQuery";
import { AxiosResponse } from "axios";
import { EquipmentAutoComplete } from "components/common/autoComplete/equipment/equipment-autoComplete";
import { LocationAutoComplete } from "components/common/autoComplete/location/location-autoComplate";
import { useLocalStorage } from "context/LocalStorageProvider";
import { useModal } from "context/ModalStackManager";
import { useQuery } from "react-query";
import { FormButtonBox } from "../FormButtonBox";
import { FormMain } from "../FormMain";
import { FormWrapper } from "../FormWrapper";

const User = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

export const UserUpdate = () => {
  const { closeModal } = useModal();
  const { userId, userName, name, equipmentCode, setEquipmentCode, setWorkId, locationCode, setLocationCode, setPrintCode, printCode } = useLocalStorage();

  const form = useForm({
    initialValues: {
      id: userId, // pk
      name: name, // 이름
      username: userName, // 아이디
      equipmentCode: equipmentCode, //설비코드
      locationCode: locationCode,
      printCode: printCode,
    },
  });

  const { queryKey, queryFn } = users.get({
    pageSize: 20
  });
  const { data } = useQuery(queryKey, queryFn, {
    select: (data: AxiosResponse<UsersGet200Response, any>) =>
      data?.data?.rows
        ?.filter((user, index, self) =>
          user?.printerCode !== '' &&
          user?.printerCode !== null &&
          index === self.findIndex((t) => t.printerCode === user.printerCode)
        )
        .map((user) => {
          return {
            value: user?.printerCode as string,
            label: user?.printerCode as string,
          };
        }),
  });

  const { refetch } = useAuthWhoamiGet({
    onSuccess: (data) => { }
  });
  const { mutate: updateMutate } = useUsersMutationQuery("update");

  const onSubmit = () => {
    try {
      updateMutate(
        {
          userId: form.values.id as number,
          usersUserIdDeleteRequest: {
            name: form.values.name,
            username: form.values.username,
            equipmentCode: form.values.equipmentCode,
            locationCode: form.values.locationCode === "null" ? null : form.values.locationCode,
            printerCode: form.values.printCode ?? '',
          },
        },
        {
          onSuccess: (res) => {
            if (res.status === 200) {
              alert("사용자 수정 성공");
              refetch();
              setWorkId(undefined)
              setEquipmentCode(form.values.equipmentCode);
              setLocationCode(form.values.locationCode);
              setPrintCode(form.values.printCode);
              closeModal({});
            }
          },
        }
      );
    } catch (e) {
      alert("실패");
    }
  };

  return (
    <User.Wrapper>
      <User.Input
        size="xl"
        label="사용자 ID"
        defaultValue={form.values?.username}
        disabled
        styles={{ label: { fontSize: "0.9em" } }}
      />
      <User.Input
        size="xl"
        label="사용자 이름"
        defaultValue={form.values?.name}
        disabled
        styles={{ label: { fontSize: "0.9em" } }}
      />
      <EquipmentAutoComplete
        size="xl"
        label="설비"
        defaultValue={form.values.equipmentCode}
        styles={{ label: { fontSize: "0.9em" } }}
        {...form.getInputProps("equipmentCode")}
      />
      <LocationAutoComplete
        size="xl"
        label="로케이션"
        defaultValue={form.values.locationCode}
        styles={{ label: { fontSize: "0.9em" } }}
        {...form.getInputProps("locationCode")}
      />
      <Select
        size="xl"
        label="프린터"
        clearable
        defaultValue={form.values.printCode}
        styles={{ label: { fontSize: "0.9em" } }}
        {...form.getInputProps("printCode")}
        data={data as (string | SelectItem)[] ?? []}
        withinPortal
      />
      <User.ButtonBox>
        <User.Button color="gray" size="lg" onClick={closeModal}>
          취소
        </User.Button>
        <User.Button onClick={onSubmit} size="lg">
          등록
        </User.Button>
      </User.ButtonBox>
    </User.Wrapper>
  );
};
